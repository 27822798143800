export const oopTopics = [
  {
    section: "Классы",
    problems: [
      "http-router",
      "query-params",
      "randomizer",
      "tuple",
      "version-manager",
      "browser-history",
      // "lazy-chain",
      // "event-emitter", // https://leetcode.com/problems/event-emitter
    ],
  },
  {
    section: "Дескрипторы свойств",
    problems: [
      "counter",
      "fullname",
      // "object-freeze",
      "object-assign-advanced",
      // "get-and-set",
    ],
  },
  {
    section: "Proto",
    problems: [
      "object-group-by",
      "object-create",
    ],
  },
  {
    section: "Расширяем прототип",
    problems: [
      "object-get",
      "object-set",
      "array-map",
      "pipe",
    ],
  },
  {
    section: "This",
    problems: [
      "nouveau",
      "call",
      "bind",
      "arrow-function",
    ],
  },
  {
    section: "Наследование",
    problems: [
      "instanceof",
      "observable-set",
      "animals",
      "autobind",
    ],
  },
  // {
  //   section: "Итераторы",
  //   problems: [
  //     "iterable-randomizer",
  //     "iterator-range",
  //     "linked-list",
  //   ],
  // },
] as const;
