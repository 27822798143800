import {fpTopics} from "./maxcode/topics/fpTopics";
import {oopTopics} from "./maxcode/topics/oopTopics";
import {asyncTopics} from "./maxcode/topics/asyncTopics";
import {recursionTopics} from "./maxcode/topics/recursionTopics";
import {algoTopics} from "./maxcode/topics/algoTopics";
import {bitsTopics} from "./maxcode/topics/bitsTopics";
import {arrayMethodsTopics} from "./maxcode/topics/arrayMethodsTopics";
import {objectsTopics} from "./maxcode/topics/objectsTopics";
import {arraysTopics} from "./maxcode/topics/arraysTopics";
import {JavascriptMethodName} from "./js/JavascriptMethods";
import {basicsTopics} from "./maxcode/topics/basicsTopics";
import {iteratorsTopics} from "./maxcode/topics/iteratorsTopics";
import {ArrayMethod} from "./js/ArrayMethods";

export const fpCourse = {
  id: "fp",
  title: "Функциональный JavaScript",
  topics: fpTopics,
};

export const iteratorsCourse = {
  id: "iterators",
  title: "Итераторы в JavaScript",
  topics: iteratorsTopics,
};

export const oopCourse = {
  id: "oop",
  title: "ООП в JavaScript",
  topics: oopTopics,
};

export const asyncCourse = {
  id: "async",
  title: "Асинхронный JavaScript",
  topics: asyncTopics,
};

export const recursionCourse = {
  id: "recursion",
  title: "Разбираемся с рекурсией",
  topics: recursionTopics,
};

export const bitsCourse = {
  id: "bits",
  title: "Числа и биты",
  topics: bitsTopics,
}

export const algoCourse = {
  id: "algo",
  title: "Базовые алгоритмы",
  topics: algoTopics,
};

export const MainCourses = [
  fpCourse,
  oopCourse,
  asyncCourse,
  recursionCourse,
  bitsCourse,
  algoCourse,
] as const;

export const basicsCourse = {
  id: "basics",
  title: "Условия и циклы",
  topics: basicsTopics,
};

export const arraysCourse = {
  id: "arrays",
  title: "Массивы и строки",
  topics: arraysTopics,
};

export const objectsCourse = {
  id: "objects",
  title: "Объекты и сортировки",
  topics: objectsTopics,
};

export const arrayMethodsCourse = {
  id: "array-methods",
  title: "Методы массивов",
  topics: arrayMethodsTopics,
};

export const MaxcodeCourses = [
  basicsCourse,
  arraysCourse,
  objectsCourse,
  fpCourse,
  oopCourse,
  asyncCourse,
  recursionCourse,
  bitsCourse,
  algoCourse,
  arrayMethodsCourse,
  iteratorsCourse,
];

export type MaxcodeCourse = typeof MaxcodeCourses[number];
export const MAXCODE_COURSES_IDS = MaxcodeCourses.map(x => x.id);
export type MaxcodeCourseId = typeof MAXCODE_COURSES_IDS[number];

export type BasicsProblem = typeof basicsTopics[number]["problems"][number];
export type ArraysProblem = typeof arraysTopics[number]["problems"][number];
export type ObjectsProblem = typeof objectsTopics[number]["problems"][number];
export type FpProblem = typeof fpTopics[number]["problems"][number];
export type OopProblem = typeof oopTopics[number]["problems"][number];
export type RecursionProblem = typeof recursionTopics[number]["problems"][number];
export type AsyncProblem = typeof asyncTopics[number]["problems"][number];
export type ArrayMethodsProblem = typeof arrayMethodsTopics[number]["problems"][number];

export type MaxcodeProblem = typeof MaxcodeCourses[number]["topics"][number]["problems"][number];

export function isMaxcodeProblem(id: string): id is MaxcodeProblem {
  return MaxcodeCourses.flatMap(course => course.topics.flatMap(t => t.problems)).includes(id as MaxcodeProblem);
}

export const arraysProblem2methods: Record<ArraysProblem, JavascriptMethodName[]> = {
  "homogenous-arrays": ["Array#filter", "Array#every", "typeof"],
  "email-obfuscator": ["String#replace", "String#replaceAll"],
  "like-dislike": ["Array#reduce"],
  "only-digits": ["Array#every", "Array.from"],
  "pivot-index": ["Array#reduce", "Array#slice"],
  "rgb-to-hex": ["Number#toString", "String#padStart", "Array#join", "Array#map"],
  "rle-encode": ["String#replace"],
  "max-number": ["Math.max", "Array#reduce"],
  "ndfl": ["Math.min"],
  "analog-clock": ["Math.abs", "Math.min", "Array#map", "String#split"],
  // "list-filtering": ["Array#filter", "typeof"],
  "2d-array-search": ["Array#flat", "Array#some"],
  "format-price": ["String#slice", "Number#toString", "Array#unshift", "Array#join"],
  "shortest-word": ["Array#map", "Math.min", "String#split"],
  "top-words": ["Array#filter", "Array#slice", "RegExp#test", "String#startsWith", "String#toLowerCase"],
  "change-date-format": ["String#replace"],
  "rle-decode": ["String#replace"],
  "balance-checking": ["String#replace", "String#split", "Array#filter", "Destructuring assignment", "Number#toFixed"],
  "count-smiley-faces": ["Array#filter", "RegExp#test"],
  "playlist": ["Array#reduce", "String#split", "Array#reverse", "Array#map", "Math.floor", "Array#shift", "String#padStart", "Array#join", "Remainder"],
  // "chess-knight": ["Array#map", "String#charCodeAt", "Array#includes", "Array#filter", "String.fromCharCode"],
  // "chess-rock": ["Array#filter", "Array#find", "Array#findLast", "Array#sort"],
};

export const objectsProblem2methods: Record<ObjectsProblem, JavascriptMethodName[]> = {
  "increment-age": ["Spread syntax"],
  "shopping-cart": ["delete", "Spread syntax", "Destructuring assignment"],
  "replace-digits": ["Array#map", "String#replace", "Array#join", "String#split", "Array#filter", "Object#hasOwnProperty", "Object.hasOwn", "Object.create", "in"],
  "greet-developers": ["Array#map", "Spread syntax"],
  "find-developers": ["Array#map", "Array#flatMap", "Array#sort", "Array#filter"],
  "extract-key": ["Array#map"],
  "remove-key": ["Array#map", "Spread syntax", "Destructuring assignment"],
  "keys-projection": ["Array#map", "Array#filter", "Object.entries", "Object.fromEntries", "Array#includes"],
  "hex-to-rgb": ["String#replace", "parseInt", "String#slice", "Array#map"],
  "who-is-online": ["Object.entries", "Object.fromEntries", "Array#filter"],
  "browsers-list": ["Array#flatMap", "Array#filter", "Array#map", "Array.from"],
  "equal-arrays": ["Map#get", "Map#set", "Map#values", "Iterator#every", "Nullish coalescing operator"],
  "csv-parser": ["String#split", "Array#map", "Object.fromEntries"],
  "total-income": ["Array#reduce"],
  "the-office": ["Array#reduce", "Object.values"],
  "most-frequent-sum": ["Array#map", "Object.groupBy", "Object.values", "Math.max", "Array#filter", "Object.keys"],
  "compare-objects": ["Object.keys", "Object.is", "Array#every"],
  "non-unique-numbers": ["Object.create", "Array#filter", "Nullish coalescing assignment"],
  "movies-rating": ["Array#push", "Object.create", "Nullish coalescing assignment"],
  "is-anagram": ["Object.values", "Array#every", "Nullish coalescing assignment"],
  "unique-number": ["Nullish coalescing assignment", "Set#add", "Set#delete"],
  "inner-join": ["Array#filter", "Array#map", "Array#flatMap"],
  "effective-users": ["Object.keys", "Array#filter", "Object.create", "Nullish coalescing assignment", "Logical AND assignment"],
  "valid-brackets": ["in", "Array#at", "Array#push"],
  // "polish-notation": [],
  // "remove-duplicate-words": ["Set#add", "Array.from"],
  "merge-objects": ["Object.assign"],
  "where": ["Object.keys", "Array#filter", "Array#every"],
  "group-anagrams": ["Object.groupBy", "Object.values", "Object.create", "Nullish coalescing assignment"],
  "invert-object": ["Array#push"],

  "hash-to-array": ["Object.entries", "Object.keys", "Array#sort"],
  "my-languages": ["Object.keys", "Array#sort", "Array#filter"],
  "case-insensitive": ["String#replace", "Array#sort", "String#localeCompare"],
  "sort-by-vowels": ["Array#toSorted", "String#matchAll", "Math.max", "Iterator#map"],
  "sort-students": ["Array#sort", "Array#toSorted", "String#split", "String#charCodeAt", "Array#map", "String#localeCompare"],
  "sort-the-odd": ["Array#filter", "Array#sort"],
  "sort-by-freq": ["Object.groupBy", "Object.create", "Array#sort", "Object.values", "String#localeCompare", "Array#flat", "String#split", "Array#join", "Nullish coalescing assignment"],
  "teams-ranking": ["Array#sort"],
  "best-results": ["Object.groupBy", "Object.values", "Array#map", "Array#sort", "String#split", "Array#reverse", "Array#join", "String#localeCompare"],
  "top-methods": ["Array#flatMap", "Array#map", "Array#sort", "String#localeCompare", "Array#filter"],
};

export const fpProblems2methods: Record<FpProblem, JavascriptMethodName[]> = {
  "filter": [],
  "for-each": [],
  "map": [],
  // "every": [],
  // "some": [],
  "zip": ["Math.min"],
  "reduce": [],
  "sort": [],
  "find-integer": ["Array#every"],
  "plural": [],
  "select": ["Array#reduce", "Array#filter", "Object.keys", "Array#toSorted"],
  "group-by-equality": ["Array#find"],
  "map-group-by": ["Map#get", "Map#set", "Map#has", "Array#push"],
  "map-group-by-advanced": ["Map#get", "Map#set", "Map#has"],
  "super-power-frequency": ["Map#get", "Map#set", "Map#has"],
  "repeater": ["Remainder assignment"],
  "fibonacci-generator": [],
  "prime-generator": [],
  "multi-predicate": ["Array#every"],
  "compose": ["Array#reduceRight"],
  "once": [],
  "memo": ["Map#get", "Map#set", "Map#has"],
  "memo-advanced": ["Map#get", "Map#set", "Map#has"],
  "spy": ["Set#add", "Set#has"],

  "args-validator": [],
  "golang-errors": [],
  "named-args": ["String#match", "String#replace", "Function#toString", "String#split", "Array#map"],

  "string-joining": ["Array#join"],
  "sum": [],
  "partial-application": ["Array#push"],
  "currying": ["Function#length"],
  "uncurry": [],

  "expect-to-be": ["Object.is"],
  "expect-to-be-close-to": ["Math.abs"],
  "expect-to-throw": [],

  "create-store": [],
  "combine-reducers": [],
  "middlewares": [],
  "create-slice": [],
  "create-async-thunk": [],
};

export const recursionProblems2methods: Record<RecursionProblem, JavascriptMethodName[]> = {
  "max-depth": ["Array.isArray", "Object.values", "Math.max", "Array#map"],
  "tree-sum": [],
  "tree-max-path": ["Math.max"],
  "deep-compare": ["Array#every", "Object.keys"],
  "smart-sum": ["Array.isArray"],
  "flatten-arr": ["Array.isArray"],
  "add-depth": [],
  "flatten-obj": [],
  "verify-bst": [],
  "clone": [],
  "subsequence": [],
  // "restroom-permutations": [],
  "coins-change": [],
  "coins-change-list": [],
  "combos": [],
  "id-to-children": [],
  "id-to-parent": [],
  "breadcrumbs": [],
  "descendants": [],
  "islands-count": [],
  "islands-max-area": [],
  "boggle-word": [],
  "min-path-2d": [],
  "permutations": [],
  "permutations-2": [],
  "cross-rate": [],
};

export const oopProblems2methods: Record<OopProblem, JavascriptMethodName[]> = {
  "http-router": ["Object.create", "Optional chaining", "Nullish coalescing operator"],
  "query-params": [],
  "randomizer": ["Number.isInteger", "Array#sort", "Array#fill", "Array#map", "Math.random"],
  "tuple": ["new.target", "instanceof"],
  "version-manager": [],
  "browser-history": [],
// "lazy-chain": [],
// "event-emitter": [],
  "counter": ["Object.defineProperty"],
  "fullname": ["String#split", "Object.defineProperty"],
// "object-freeze": [],
  "object-assign-advanced": ["Object.getOwnPropertyDescriptor"],
// "get-and-set": [],
  "object-group-by": ["Object.create", "Array#push"],
  "object-create": ["new"],
  "object-get": ["Array#reduce", "String#split"],
  "object-set": ["String#split"],
  "array-map": ["Function#call"],
  "pipe": ["Function#bind"],
  "nouveau": ["Function#apply"],
  "call": [],
  "bind": ["Function#call"],
  "arrow-function": [],
  "instanceof": [],
  "observable-set": [],
  "animals": ["Function#call"],
  "autobind": ["Function#bind", "Object.getOwnPropertyNames"]
  // "iterable-randomizer": [],
  // "iterator-range": [],
  // "linked-list": [],
};

export const asyncProblems2methods: Record<AsyncProblem, JavascriptMethodName[]> = {
  "add-two-promises": ["Promise#then"],
  "add-all-promises": ["Array#reduce", "Promise.all", "Promise.resolve", "Promise#then"],
  "promise-logic": ["Promise.all", "Promise#then"],
  "count-fulfilled-promises": ["Promise.allSettled", "Array#filter", "Array#reduce"],

  "race": ["Promise#then"],
  "simple-all": ["Promise#then"],
  "all": ["Promise#then"],
  "all-settled": ["Promise#then"],
  "any": ["Promise#then"],

  "sleep": [],
  "microtasks": [],
  "event-loop": [],
  "time-limit": ["Promise.race"],
  "state": ["Promise#then"],

  "chain": [],
  "then": [],
  "catch": ["Promise#then"],
  "finally": ["Promise#then"],

  "compose-async": ["Promise.resolve", "Array#reduceRight", "Promise#then"],
  "polling": ["Promise#then"],
  "promise-batch": ["Promise.all", "Promise#then", "Array#slice", "Array#map"],
  "retry": ["Promise#catch"],
  "promise-pool": ["Promise#then", "Promise.withResolvers"],
  "promise-pool-memo": [],

  "async-try-catch": [],
  "async-event-loop": [],
  "boost-async-flatten": ["Promise.all"],

  "promisify": [],
  "callbackify": ["Promise#then"],
  "compose-callback": ["Array#reduceRight"],

  "file-system": ["String#endsWith", "Array#push"],
  "products": ["Promise#catch", "Function#bind", "Array#map", "Promise.all", "instanceof"],

  // "promise-resolve": [],
  // "promise-reject": [],
  // "promise-try": [],
  // "with-resolvers": [],
  // "promise-diy": [],
  // "async-await-diy": [],
  // "cancelable-promise": [],
  // "with-fallback": [],
  // "parallel-callback": [],
  // "raf": [],

  "cache": [],
  "debounce": [],
  "throttle": [],
};

export const arrayMethodsProblems2methods: Record<ArrayMethodsProblem, JavascriptMethodName[]> = {
  "map": [],
  "filter": [],
  "every": [],
  "some": [],
  "for-each": [],
  "flat": [],
  "flat-map": [],
  "reduce": [],
  "reduce-right": [],
  "includes": [],
  "index-of": [],
  "last-index-of": [],
  "find": [],
  "find-last": [],
  "find-index": [],
  "find-last-index": [],
  "reverse": [],
  "sort": [],
  "at": [],
  "slice": [],
  "join": [],
  "to-reversed": [],
  "to-sorted": [],
  "to-spliced": [],
  "with": [],
  "concat": [],
  "to-string": [],
  "to-locale-string": [],
  "pop": [],
  "push": [],
  "shift": [],
  "unshift": [],
  "splice": [],
  "fill": [],
  "copy-within": [],
  "keys": [],
  "values": [],
  "entries": [],
};

export const problem2methods: Partial<Record<MaxcodeProblem, JavascriptMethodName[]>> = {
  ...fpProblems2methods,
  ...arraysProblem2methods,
  ...objectsProblem2methods,
  ...recursionProblems2methods,
  ...oopProblems2methods,
  ...asyncProblems2methods,
  ...arrayMethodsProblems2methods
};

export const methods2problems = Object.entries(problem2methods)
  .flatMap(([problem, methods]) => methods.map(method => [problem, method]))
  // @ts-ignore
  .reduce((acc, [problem, method]) => {
// @ts-ignore
    acc[method] ??= [];
// @ts-ignore
    acc[method].push(problem);
    return acc;
  }, {}) as Partial<Record<JavascriptMethodName, MaxcodeProblem[]>>;
