import {useSelector} from "../../app/hooks";
import React, {Fragment} from "react";
import cn from "classnames";
import s from "./RoadmapProblems.module.css";
import {MaxcodeCourses, problem2methods} from "../../domain/problems/MaxcodeCourses";
import {CoursePromoLink} from "../CoursesList/CoursePromoLink";
import {Problem} from "../../api/problems";
import {Link} from "react-router-dom";

interface RoadmapProblemsProps {
  sectionOffset: number;
  sections: {
    id: string;
    title: string;
    problems: string[];
  }[];
}

export function RoadmapProblems({
  sectionOffset = 0,
  sections,
}: RoadmapProblemsProps) {
  const problemsMap = useSelector(state => state.problems);
  const username = useSelector(state => state.user.user?.username);

  return (
    <div className={s.wrapper}>
      {sections.map(({id, title, problems}, sectionIndex) => (
        <Fragment>
          <h2 className={cn(s.sectionTitle, id)}>
            {title}
          </h2>

          {MaxcodeCourses.some(x => x.id === id) && (
            <div className={cn(s.promo, id)}>
              <CoursePromoLink course={MaxcodeCourses.find(x => x.id === id)!}/>
            </div>
          )}

          <ul className={cn(s.sectionList, id)}>
            {problems.map((slug, problemIndex) => {
              const problem = problemsMap[slug] as Problem | undefined;

              const done = problem !== undefined && problem.submissionsPreviews.some(sp => sp.status === "OK");
              const attempt = problem !== undefined && problem.submissionsPreviews.length > 0;

              /* @ts-ignore */
              const methods = problem2methods[problem?.slug] ?? [];

              return (
                <li className={s.sectionItem}>
                  <span className={cn(
                    s.index,
                    attempt && s.attempt,
                    done && s.done,
                    (problem === undefined || !problem.access.includes("DESCRIPTION")) && s.locked,
                  )}>
                    {((sectionIndex + sectionOffset) * 100 + problemIndex + 1).toString().padStart(3, "0")}
                  </span>
                  <Link
                    className={s.problem}
                    to={`/problems/${slug}`}
                  >
                    {problem?.title ?? slug.replaceAll("-", " ")}
                    {username === "sinyakov" && (
                      <React.Fragment>
                        {<span className={s.problemSolution}>{methods.length > 0 && "📜"}</span>}
                        {<span className={s.problemHint}>{problem?.access.includes("SOLUTION") && "🩻"}</span>}
                        {<span className={s.problemHint}>{problem?.youtubeId && "▶️"}</span>}
                      </React.Fragment>
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Fragment>
      ))}
    </div>
  );
}
