import {MaxcodePromo} from "../../MaxcodePromo/MaxcodePromo";
import s from "./CoursesPageContent.module.css";
import {
  algoCourse,
  arraysCourse,
  asyncCourse,
  bitsCourse,
  fpCourse,
  objectsCourse,
  oopCourse,
  recursionCourse
} from "../../../domain/problems/MaxcodeCourses";
import cn from "classnames";
import {Link} from "react-router-dom";
import {AsyncCourseShortDescription} from "../../Payment/Payment";
import ava from "./ava.png"
import {CoursePromoLink} from "../../CoursesList/CoursePromoLink";
import React from "react";
import {algoTopics} from "../../../domain/problems/maxcode/topics/algoTopics";
import {CoursesList} from "../../CoursesList/CoursesList";

export const MainPageCourses = [
  // basicsCourse,
  arraysCourse,
  objectsCourse,
  fpCourse,
  oopCourse,
  recursionCourse,
  asyncCourse,
  // algoCourse,
];

// const patternsCourse = {
//   id: "stack",
//   title: "Архитектурные паттерны",
//   topics: [{}],
// };
//
// function GradesList() {
//   return (
//     <div className={s.grades}>
//       <div className={s.col}>
//         <h2>Junior</h2>
//         <div className={s.colCourses}>
//           <CoursePromoLink course={arraysCourse}/>
//           <CoursePromoLink course={objectsCourse}/>
//           <CoursePromoLink course={recursionCourse}/>
//         </div>
//       </div>
//       <div className={s.col}>
//         <h2>Middle</h2>
//         <div className={s.colCourses}>
//           <CoursePromoLink course={fpCourse}/>
//           <CoursePromoLink course={oopCourse}/>
//           <CoursePromoLink course={asyncCourse}/>
//         </div>
//       </div>
//       <div className={s.col}>
//         <h2>Senior</h2>
//         <div className={s.colCourses}>
//           <CoursePromoLink course={bitsCourse}/>
//           <CoursePromoLink course={algoCourse}/>
//           <CoursePromoLink course={patternsCourse as any}/>
//         </div>
//       </div>
//     </div>
//   )
// }

export function CoursesPageContent() {
  return (
    <div className={s.main}>
      <div className={s.wrapper}>
        <CoursesList courses={MainPageCourses}/>
        {/*<GradesList/>*/}
      </div>
      <div className={s.bottom}>
        <div className={s.wrapper}>
          <MaxcodePromo/>
        </div>
      </div>
      {/*<div className={s.promoWrapper}>*/}
      {/*  <div className={s.promoWrapperBlock}>*/}
      {/*    <AsyncLandingPromoLink />*/}
      {/*  </div>*/}
      {/*  <RoadmapsList/>*/}
      {/*  <AboutMe/>*/}
      {/*</div>*/}
    </div>
  );
}

export function AboutMe() {
  return (
    <div className={s.author}>
      <div className={s.authorContent}>
        <div>
          <h3>Главный редактор</h3>
          <h2>Максим Синяков</h2>
          ✓ Фулстек-разработчик (Java + JavaScript) с опытом более 10 лет<br/>
          ✓ С 2019 года провел более 9 тысяч часов персональных занятий (150+ учеников)<br/>
        </div>
        <img src={ava} className={s.ava}/>
      </div>
      <div className={s.social}>
        <span>YouTube</span>
        <span>Telegram</span>
      </div>
    </div>
  );
}

export function RoadmapsList() {
  return (
    <div className={s.promoWrapperBlock}>
      <div className={s.roadmaps}>
        {/*<Link to="/roadmaps/react" className={cn(s.roadmap, "react")}>*/}
        {/*  <h3>React & Redux</h3>*/}
        {/*  <p>Макеты компонентов</p>*/}
        {/*</Link>*/}
        <Link to="/cheatsheets/javascript" className={cn(s.roadmap, "javascript")}>
          <h3>JavaScript</h3>
          <p>Справочник методов с примерами и задачами</p>
        </Link>
        <Link to="/roadmaps/typescript" className={cn(s.roadmap, "typescript")}>
          <h3>TypeScript</h3>
          <p>Покрываем знакомые задачи типами</p>
        </Link>
        <Link to="/roadmaps/dsa" className={cn(s.roadmap, "dsa")}>
          <h3>Алгоритмы</h3>
          <p>Подборка задач с LeetCode по темам</p>
        </Link>
      </div>
    </div>
  );
}

export function AsyncLandingPromoLink() {
  return (
    <Link to="/promo/async" className={cn(s.asyncPromo, "async")}>
      <h3 className={cn(s.asyncPromoTitle, "async")}>
        Практический курс по асинхронному JavaScript
      </h3>
      <AsyncCourseShortDescription/>
      <span>Узнать больше</span>
    </Link>
  );
}
