export const recursionTopics = [
  {
    section: "Основы",
    problems: [
      "max-depth",
      "tree-sum",
      "tree-max-path",
      "deep-compare",
      "smart-sum",
      "flatten-arr",
    ],
  },
  {
    section: "Дополнительный аргумент",
    problems: [
      "add-depth",
      "flatten-obj",
      "verify-bst",
      "clone",
    ],
  },
  {
    section: "Базовый случай",
    problems: [
      // "restroom-permutations",
      "subsequence",
      "coins-change",
      "coins-change-list",
      "combos",
      "permutations",
      "permutations-2",
    ],
  },
  {
    section: "Вложенные категории",
    problems: [
      "id-to-children",
      "id-to-parent",
      "breadcrumbs",
      "descendants",
    ],
  },
  {
    section: "Обход в глубину",
    problems: [
      "islands-count",
      "islands-max-area",
      "boggle-word",
      "cross-rate"
    ],
  },
  {
    section: "Рекурсия + кэширование",
    problems: [
      "min-path-2d",
      // "queens",
      // "sudoku",
    ],
  },
  // {
  //   section: "Cache & Dynamic Programming",
  //   problems: [
  //     "perfect-squares",
  //   ],
  // },
] as const;

