export const arraysTopics = [
  {
    section: "Массивы",
    problems: [
      "shortest-word",
      "top-words",
      "max-number",
      "2d-array-search",
      "playlist",
      "like-dislike",
      "pivot-index",
      "only-digits",
      "homogenous-arrays",
      // "check-the-exam",
      // "small-enough",
      // "format-words",
      // "array-diff",
      // "lottery-ticket",
      // "two-sum",
      // "list-filtering",
      // "sum-of-positive",
      // "build-a-square",
      // "vowel-count",
      // "smallest-value",
      // "find-the-capitals",
      // "insert-dashes",
      // "row-weights",
      // "scrolling-text",
      // "expanded-form",
      // "every-value-is-array",
      // "make-a-square-box",
      // "magic-index",
      // "length-of-missing-array",
      // "data-reverse",
      // "proof-read",
      // "delete-digit",
      // "element-equals-its-index",
      // "zero-plentiful-array",
    ],
  }, {
    section: "Условия",
    problems: [
      "ndfl",
      "analog-clock",
    ],
  }, {
    section: "Строки",
    problems: [
      "format-price",
      "change-date-format",
      "rle-encode",
      "rle-decode",
      "count-smiley-faces",
      "balance-checking",
      "email-obfuscator",
      "rgb-to-hex",
      // "valid-parentheses",
      // "kebabize",
      // "a10n-abbreviation",
      // "ip-validation",
      // "string-incrementer",
      // "string-insert-values",
    ],
  },
] as const;
