import s from "./AsciiTable.module.css";
import {Helmet} from "react-helmet-async";
import React, {useState} from "react";

const color = (letter: string) => {
  if (letter >= "0" && letter <= "9") {
    return "#ffd39a";
  }
  if (letter >= "A" && letter <= "Z") {
    return "#afffaf";
  }
  if (letter >= "a" && letter <= "z") {
    return "#abb7ff";
  }
  if (letter >= "а" && letter <= "я" || letter === "ё") {
    return "#ff9393";
  }
  if (letter >= "А" && letter <= "Я" || letter === "Ё") {
    return "#fface4";
  }
  return "#eee";
}

const BASES = ["dec", "hex"] as const;

const base2num: Record<typeof BASES[number], number> = {
  // "bin": 2,
  "dec": 10,
  "hex": 16,
}

export function AsciiTable() {
  const [base, setBase] = useState<typeof BASES[number]>("dec");

  return (
    <div>
      <Helmet>
        <title>
          Таблица ASCII & Unicode
        </title>
      </Helmet>
      <h1>Таблица ASCII & Unicode</h1>

      <div className={s.wrap}>
        <div className={s.table}>
          {[1, 2, 3, 6, 32, 33, 34, 100, 4000, 4200].map((col) => {
            return (
              <div className={s.column}>
                {Array(32).fill(0)
                  .map((_, i) => i + 32 * col)
                  .map(code => (
                    <div className={s.cell} style={{background: color(String.fromCodePoint(code))}}>
                      <span>{code.toString(base2num[base])}</span> <span>{String.fromCodePoint(code)}</span>
                    </div>
                  ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}

