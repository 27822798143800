import React from "react";
import {Helmet} from "react-helmet-async";
import {MaxcodeProblem} from "../../domain/problems/MaxcodeCourses";
import s from "./RoadmapTypeScript.module.css";
import {Link} from "react-router-dom";
import {Problem} from "../../api/problems";
import cn from "classnames";
import {useSelector} from "../../app/hooks";

type TypescriptFeature =
  | "Map"
  | "Iterable"
  | "Set"
  | "Record"
  | "Array"
  | "Partial"
  | "Omit"
  | "void"
  | "Type Guard"
  | "Overloading"
  | "Generic"
  | "Multiple Generics"
  | "Function"
  | "Promise"
  | "Union Type"
  | "Global Type"
  | "Type Indexing"
  | "Type from Value"
  | "keyof"
  | "Generic Extends"
  | "Interface Extends"
  | "as unknown as"
  | "Bang!"
  | "Template Literal Types"


const problem2features: Partial<Record<MaxcodeProblem, TypescriptFeature[]>> = {
  "greet-developers": ["Interface Extends"],
  "sort-by-freq": ["Record"],
  "top-methods": [],
  "teams-ranking": [],
  "tree-sum": [],
  "id-to-parent": [],
  "http-router": ["Union Type"],
  "query-params": [],
  "find-integer": ["Function"],
  "file-system": ["Function"],
  "hex-to-rgb": ["Template Literal Types"],
  "who-is-online": ["Partial", "Record"],
  "total-income": ["Record"],

  "filter": ["Generic"],
  "for-each": ["Generic", "void"],
  "sort": ["Generic"],
  "map": ["Multiple Generics"],
  "reduce": ["Overloading", "Multiple Generics"],

  "state": ["Promise"],
  "group-by-equality": ["Generic"],
  "zip": ["Multiple Generics"],
  "where": ["Partial"],
  "object-group-by": ["Iterable", "Generic"],
  "map-group-by": ["Map", "Multiple Generics"],
  "map-group-by-advanced": ["Map", "Multiple Generics", "as unknown as"],
  "super-power-frequency": ["Map", "Multiple Generics"],
  "once": ["Function", "Union Type"],
  "multi-predicate": ["Generic", "Function"],
  "compose": ["Overloading", "Multiple Generics"],
  "memo": ["Map", "Bang!"],
  "spy": ["Generic Extends", "Type Indexing"],
  "polling": ["Type Guard"],
  "promisify": ["Overloading"],
  "cache": ["Multiple Generics", "Map"],
  "pipe": ["Global Type"],
  "array-map": ["Global Type"],
  "extract-key": ["Multiple Generics", "Generic Extends", "keyof"],
  "remove-key": ["keyof", "Generic Extends", "Omit"],
  "keys-projection": ["keyof", "Generic Extends", "Omit"],
};

const problems = Object.keys(problem2features) as MaxcodeProblem[];

export function RoadmapTypeScript() {
  const problemsMap = useSelector(state => state.problems);

  return (
    <div>
      <Helmet>
        <title>
          Практический роадмап по TypeScript
        </title>
      </Helmet>
      <h1 className={s.mainTitle}>Список задач по TypeScript</h1>
      <div className={s.preview}>
        <h2>Как изучать TypeScript</h2>
        <p>Так же, как и изучение джаваскрипта, изучение тайпскрипта возможно только с помощью решения задач. При этом
          полезно взять уже знакомые задачи, чтобы сконцентрироваться на типах. Изучение TypeScript можно разделить на 4
          части.</p>
        <p><strong>I. Базовые типы и интерфейсы.</strong> На данном этапе необходимо научиться описывать типы
          переменных, функций и классов. Для этого подойдут задачи на работы с данными: массивы, <Link
            to="/courses/objects">объекты</Link> и <Link
            to="/courses/oop">ООП</Link>.</p>
        <p><strong>II. Дженерики в функциях.</strong> Проще всего понять необходимость дженериков можно реализуя
          некоторые <Link to="/courses/array-methods">методы массивов</Link>. Грубо говоря, дженерики нужны для описания
          функций, которые могут работать со значениями разных типов. Лучше всего для практики подходят задачи на <Link
            to="/courses/fp">ФП</Link>.</p>
        <p><strong>III. Дженерики в типах.</strong> Для изучения чуть более продвинутых тем (conditional types, infer,
          never, unknown, utility types) можно решать задачи из списка <a
            href="https://github.com/type-challenges/type-challenges">Type Challenges</a>. В них необходимо создать
          какой-то Generic Type, исполняемый код там не подразумевается.</p>
        <p><strong>IV. Применение в React.</strong> Для тайпскрипта в реакте применимо все что выше, но дополнительно
          можно написать несколько дженерик компонент. Например, компонентов таблицы, которая умеет отображать массив
          произвольных объектов.</p>
        {/*<p>В списке на этой странице подобраны задачи, покрывающие первые две части: базовые типы и дженерики.</p>*/}

        <h2>Как решать задачи</h2>

        <ul>
          <li>Мы покрываем типами знакомые задачи из <Link to="/roadmaps/javascript">курса по JavaScript</Link></li>
          <li>Пишем код так, чтобы ни одна переменная не имела тип any</li>
          <li>Отправить решение на TypeScript в систему пока нельзя</li>
          <li>Запускаем код локально через <a href="https://www.npmjs.com/package/ts-node" target="_blank">библиотеку
            ts-node</a></li>
          <li>Теория: <a href="https://www.typescriptlang.org/cheatsheets/" target="_blank">официальная шпаргалка по
            тайпскрипту</a></li>
        </ul>
      </div>

      <div className={s.problems}>
        {problems.map((slug, problemIndex) => {
          const problem = problemsMap[slug] as Problem | undefined;

          const done = problem !== undefined && problem.submissionsPreviews.some(sp => sp.status === "OK");
          const attempt = problem !== undefined && problem.submissionsPreviews.length > 0;

          return (
            <li className={s.sectionItem}>
                <span className={cn(
                  s.index,
                  attempt && s.attempt,
                  done && s.done,
                  (problem === undefined || !problem.access.includes("DESCRIPTION")) && s.locked,
                )}>
                  {(problemIndex + 1).toString().padStart(3, "0")}
                </span>
              <Link
                className={s.problem}
                to={`/problems/${slug}`}
              >
                {slug.replaceAll("-", " ")}
              </Link>
              <div className={s.tags}>
                {problem2features[slug]?.map(tag => (
                  <span className={s.tag}>{tag}</span>
                ))}
              </div>
            </li>
          );
        })}
      </div>
    </div>
  );
}
