import s from "./Video.module.css";

const sections = [
  {
    title: "Объекты и сортировки",
    videos: [
      {youtubeId: "BKiDbLlX7Xg", title: "Сортировка массивов",},
      {youtubeId: "4mUZL6zXBH4", title: "Итераторы и Iterable",},
      {youtubeId: "xxxxxxxxxxx", title: "null prototype object",},
      {youtubeId: "xxxxxxxxxxx", title: "Оператор ??=",},
    ],
  },
  {
    title: "Функциональное программирование",
    videos: [
      {youtubeId: "xxxxxxxxxxx", title: "Функции-декораторы",},
      {youtubeId: "xxxxxxxxxxx", title: "Unit-тесты: покрываем тестами функцию",},
      {youtubeId: "oYedWAz07Z4", title: "Unit-тесты: как устроены внутри",},
    ],
  },
  {
    title: "ООП",
    videos: [
      {youtubeId: "xxxxxxxxxxx", title: "Прототипное наследование",},
    ],
  },
  {
    title: "Рекурсия",
    videos: [
      {youtubeId: "xxxxxxxxxxx", title: "Как решать рекурсию",},
      {youtubeId: "D6MGnUBSdSU", title: "Стэк вызовов",},
    ],
  },
  {
    title: "Асинхронность",
    videos: [
      {youtubeId: "xxxxxxxxxxx", title: "Цепочка промисов",},
      {youtubeId: "wJW4UEMBgGY", title: "Promise.race",},
      {youtubeId: "xxxxxxxxxxx", title: "Promise.all",},
      {youtubeId: "xxxxxxxxxxx", title: "Метод catch",},
      {youtubeId: "xxxxxxxxxxx", title: "Асинхронная очередь",},
    ],
  },
  {
    title: "Языки программирования",
    videos: [
      {youtubeId: "xxxxxxxxxxx", title: "Передача по ссылке и по значению",},
      {youtubeId: "xxxxxxxxxxx", title: "Виды типизации",},
    ],
  },
  {
    title: "Сеть и браузеры",
    videos: [
      {youtubeId: "xxxxxxxxxxx", title: "HTTP-протокол на практике",},
      {youtubeId: "xxxxxxxxxxx", title: "Как работает CORS",},
    ],
  },
];

export function Video() {
  return (
    <div>
      <h1 className={s.mainTitle}>Видео на YouTube</h1>
      <div className={s.preview}>
        <p>Я записываю видео по тем темам, для которых нет нормального объяснения на ютубе на русском языке. На этой
          странице серыми прямоугольниками обозначены видео, которые я еще не записал. Это наш контент-план до конца
          2024 года :)</p>
      </div>
      {sections.map(section => {
        return (
          <div className={s.section}>
            <h2 className={s.sectionTitle}>{section.title}</h2>
            <div className={s.gallery}>
              {section.videos.map(video => {
                const src = `https://i.ytimg.com/vi_webp/${video.youtubeId}/maxresdefault.webp`;
                return (
                  <div className={s.video}>
                    {video.youtubeId === "xxxxxxxxxxx"
                      ? <div className={s.xxx}/>
                      : (
                        <a
                          target="_blank"
                          href={"https://www.youtube.com/watch?v=" + video.youtubeId}
                        >
                          <img className={s.videoThumbnail} src={src}/>
                        </a>
                      )
                    }
                    <h3 className={s.videoTitle}>{video.title}</h3>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
