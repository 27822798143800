import {useSelector} from "../../app/hooks";
import s from "./TwoColumnLayout.module.css";
import {NavLink} from "react-router-dom";
import {GithubAuthLink} from "../GithubAuthLink/GithubAuthLink";
import React from "react";
import cn from "classnames";

export function MainHeader() {
  const {user, fallback} = useSelector(s => s.user);

  return (
    <div className={s.header}>
      <NavLink
        className={s.headerMainLink}
        to={user === null ? "/" : "/courses"}
      >
        maxcode.dev
      </NavLink>
      <NavLink
        to={"/roadmaps/javascript"}
        className={cn(s.headerLink, "javascript")}
      >
        JavaScript
      </NavLink>
      <NavLink
        to={"/cheatsheets/javascript"}
        className={cn(s.headerLink, "cheatsheet-javascript")}
      >
        Методы JS
      </NavLink>
      {/*<NavLink*/}
      {/*  to={"/cheatsheets/ecmascript"}*/}
      {/*  className={cn(s.headerLink, "cheatsheet-ecmascript")}*/}
      {/*>*/}
      {/*  Таймлайн ES*/}
      {/*</NavLink>*/}
      <NavLink
        to={"/help"}
        className={cn(s.headerLink, "help")}
      >
        Как пользоваться
      </NavLink>
      <NavLink
        to={"/video"}
        className={cn(s.headerLink, "youtube")}
      >
        Видео
      </NavLink>
      <a
        href="https://t.me/s/MaxCodeDev"
        target="_blank"
        className={cn(s.headerLink, "telegram")}
      >
        Новости
      </a>
      <div className={s.headerAuth}>
        {!fallback && (user === null ? (
          <div className={s.githubAuth}>
            <GithubAuthLink/>
          </div>
        ) : (
          <NavLink className={s.user} to="/profile">
            <img className={s.userAvatar} src={user.avatarUrl}/>
            {user.username}
          </NavLink>
        ))}
      </div>
    </div>
  );
}
