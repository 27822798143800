export const ArrayMethods = [
  {
    method: "Array#map",
    argument: "mapper",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      [1, 3, -5].map(x => x * 2) === [2, 6, -10]
      ["aa", "b"].map(x => x.length) === [2, 1]
    `,
  },
  {
    method: "Array#filter",
    argument: "predicate",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      const arr = [1, 5, 8, 2, 12];
      
      arr.filter(x => x > 0) === [1, 5, 8, 2, 12]
      arr.filter(x => x % 2 === 0) === [8, 2, 12]
    `,
  },
  {
    method: "Array#every",
    argument: "predicate",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      const arr = [1, 5, 8, 2, 12];
      
      arr.every(x => x > 0) === true
      arr.every(x => x % 2 === 0) === false
    `,
  },
  {
    method: "Array#some",
    argument: "predicate",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      const arr = [1, 5, 8, 2, 12];
      
      arr.some(x => x < 0) === false
      arr.some(x => x % 2 === 0) === true
    `,
  },
  {
    method: "Array#fill",
    argument: "value",
    returnedValue: "array",
    mutable: true,
    nodeVersion: "4.0.0",
    year: 2015,
    tier: "B",
    example: `
      const arr = Array(4).fill("x");
      
      arr === ["x", "x", "x", "x"]
    `,
  },
  {
    method: "Array#find",
    argument: "predicate",
    returnedValue: "value",
    mutable: false,
    nodeVersion: "4.0.0",
    year: 2015,
    tier: "A",
    example: `
      const arr = [1, 5, 8, 2, 12];
      
      arr.find(x => x > 6) === 8
      arr.find(x => x > 60) === undefined
    `,
  },
  {
    method: "Array#findIndex",
    argument: "predicate",
    returnedValue: "index",
    mutable: false,
    nodeVersion: "4.0.0",
    year: 2015,
    tier: "A",
    example: `
      const arr = [1, 5, 8, 2, 12];
      
      arr.findIndex(x => x > 6) === 2
      arr.findIndex(x => x > 60) === -1
    `,
  },
  {
    method: "Array#findLast",
    argument: "predicate",
    returnedValue: "value",
    mutable: false,
    nodeVersion: "18.0.0",
    year: 2022,
    tier: "A",
    example: `
      const arr = [1, 5, 8, 2, 12];
      
      arr.findLast(x => x > 6) === 12
      arr.findLast(x => x > 60) === undefined
    `,
  },
  {
    method: "Array#findLastIndex",
    argument: "predicate",
    returnedValue: "index",
    mutable: false,
    nodeVersion: "18.0.0",
    year: 2022,
    tier: "A",
    example: `
      const arr = [1, 5, 8, 2, 12];
      
      arr.findLastIndex(x => x > 6) === 4
      arr.findLastIndex(x => x > 60) === -1
    `,
  },
  {
    method: "Array#includes",
    argument: "value",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "6.0.0",
    year: 2016,
    tier: "A",
    example: `
      const arr = [1, 5, 8, 2, 12];
      
      arr.includes(8) === true
      arr.includes(60) === false
    `,
  },
  {
    method: "Array#indexOf",
    argument: "value",
    returnedValue: "index",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `
      const arr = [1, 5, 8, 2, 5, 12];
  
      arr.indexOf(5) === 1
      arr.indexOf(7) === -1
    `,
  },
  {
    method: "Array#lastIndexOf",
    argument: "value",
    returnedValue: "index",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `
      const arr = [1, 5, 8, 2, 5, 12];
  
      arr.indexOf(5) === 4
      arr.indexOf(7) === -1
    `,
  },
  {
    method: "Array#at",
    argument: "index",
    returnedValue: "value",
    mutable: false,
    nodeVersion: "16.6.0",
    year: 2021,
    tier: "A",
    example: `
      const arr = ["red", "green", "blue"];

      arr.at(-1) === "blue";
    `,
  },
  {
    method: "Array#slice",
    argument: "index",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      
      arr.slice(0, 3) === [0, 1, 2]
      arr.slice(3, 8) === [3, 4, 5, 6, 7]
      arr.slice(6) === [6, 7, 8, 9]
      arr.slice(-3) === [7, 8, 9]
      arr.slice(-5, -3) === [5, 6]
      arr.slice(3, -3) === [3, 4, 5, 6]
    `,
  },
  {
    method: "Array#join",
    argument: "string",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      const arr = ["a", "b", "c"];
      
      arr.join("+") === "a+b+c"
      arr.join(" ") === "a b c"
      arr.join("---") === "a---b---c"
      arr.join("") === "abc"
    `,
  },
  {
    method: "Array#flat",
    argument: "—",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "11.0.0",
    year: 2018,
    tier: "B",
    example: `
      const arr = [1, [2, 3, [4]], [[5, [6]]]];
      
      arr.flat() === [1, 2, 3, [4], [5, [6]]]      
      arr.flat(2) === [1, 2, 3, 4, 5, [6]]
      arr.flat(Infinity) === [1, 2, 3, 4, 5, 6]
    `,
  },
  {
    method: "Array#flatMap",
    argument: "mapper",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "11.0.0",
    year: 2018,
    tier: "B",
    example: `
      const arr = ["abc", "qwe"];
      
      arr.flatMap(word => word.split("")) ===      
      ["a", "b", "c", "q", "w", "e"]
    `,
  },
  {
    method: "Array#forEach",
    argument: "callback",
    returnedValue: "void",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
    example: `      
      ["abc", "qwe"].forEach(x => {
        console.log(x);
      });
    `,
  },
  {
    method: "Array#keys",
    argument: "—",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "D",
  },
  {
    method: "Array#push",
    argument: "value",
    returnedValue: "length",
    mutable: true,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      const arr = ["a"];
      const brr = ["d", "e"];
      
      arr.push("x"); // === 2
      // arr === ["a", "x"]
      
      arr.push("y", "z"); // === 4
      // arr === ["a", "x", "y", "z"]

      arr.push(...brr); // === 6
      // arr === ["a", "x", "y", "z", "d", "e"]      
    `,
  },
  {
    method: "Array#pop",
    argument: "—",
    returnedValue: "value",
    mutable: true,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      const arr = ["a", "b", "c"];

      arr.pop(); // === "c"
      // arr === ["a", "b"]   
    `,
  },
  {
    method: "Array#reduce",
    argument: "callback",
    returnedValue: "accumulator",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `
      const arr = [{x: 2}, {x: 3}, {x: 4}];

      arr.reduce((acc, elem) => acc * elem.x, 1)
      === 24  
    `,
  },
  {
    method: "Array#reduceRight",
    argument: "callback",
    returnedValue: "accumulator",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `
      const arr = ["A", "B", "C"];

      arr.reduceRight((acc, elem) => acc + elem, "")
      === "CBA"
    `,
  },
  {
    method: "Array#toLocaleString",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
    example: `      
      ["a", "b", "c"].toLocaleString()
      // "a,b,c"
    `,
  },
  {
    method: "Array#reverse",
    argument: "—",
    returnedValue: "array",
    mutable: true,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      const arr = [1, 2, 3];
      arr.reverse();
      arr === [3, 2, 1] 
    `,
  },
  {
    method: "Array#toReversed",
    argument: "—",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "20.0.0",
    year: 2023,
    tier: "A",
    example: `
      const arr = [1, 2, 3];
      arr.toReversed()  === [3, 2, 1]
      arr === [1, 2, 3] 
    `,
  },
  {
    method: "Array#sort",
    argument: "comparator",
    returnedValue: "array",
    mutable: true,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "A",
    example: `
      const arr = ["qt", "awe", "o"];
      arr.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
      
      arr === ["awe", "o", "qt"]
    `,
  },
  {
    method: "Array#toSorted",
    argument: "comparator",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "20.0.0",
    year: 2023,
    tier: "A",
    example: `
      const arr = ["qt", "awe", "o"];
      arr.toSorted((a, b) => {
         if (a < b) return -1;
         if (a > b) return 1;
         return 0;
      }) === ["awe", "o", "qt"]
      
      arr === ["qt", "awe", "o"]
    `,
  },
  {
    method: "Array#splice",
    argument: "index",
    returnedValue: "array",
    mutable: true,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `
      const arr = [0, 1, 2, 3, 4, 5];
      arr.splice(2, 3); // [2, 3, 4]
      arr === [0, 1, 5]
      
      const brr = [0, 1, 2, 3, 4, 5];
      brr.splice(2, 3, 7, 8, 9); // [2, 3, 4]
      brr === [0, 1, 7, 8, 9, 5]
    `,
  },
  {
    method: "Array#toSpliced",
    argument: "index",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "20.0.0",
    year: 2023,
    tier: "B",
    example: `
      const arr = [0, 1, 2, 3, 4, 5];
      arr.toSpliced(2, 3); // [0, 1, 5]
      arr === [0, 1, 2, 3, 4, 5]
      
      const brr = [0, 1, 2, 3, 4, 5];
      brr.toSpliced(2, 3, 7, 8, 9); // [0, 1, 7, 8, 9, 5]
      brr === [0, 1, 2, 3, 4, 5]
    `,
  },
  {
    method: "Array#toString",
    argument: "—",
    returnedValue: "string",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
    example: `      
      ["a", "b", "c"].toString()
      // "a,b,c"
    `,
  },
  {
    method: "Array#shift",
    argument: "—",
    returnedValue: "length",
    mutable: true,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `
      const arr = ["a", "b", "c", "d"];
      arr.shift() === "a"
      arr === ["b", "c", "d"]
    `,
  },
  {
    method: "Array#unshift",
    argument: "—",
    returnedValue: "value",
    mutable: true,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "B",
    example: `
      const arr = ["a", "b", "c", "d"];
      arr.unshift("x") === 5
      arr === ["x", "a", "b", "c", "d"];
    `,
  },
  {
    method: "Array#values",
    argument: "—",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "10.9.0",
    year: 2018,
    tier: "D",
  },
  {
    method: "Array#with",
    argument: "value",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "20.0.0",
    year: 2023,
    tier: "B",
    example: `
      const arr = ["a", "b", "c", "d"];
      arr.with(2, "x") === ["a", "b", "x", "d"]
      arr.with(-1, "x") === ["a", "b", "c", "x"]
      arr === ["a", "b", "c", "d"]
    `,
  },
  {
    method: "Array#concat",
    argument: "array",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2013,
    tier: "D",
    example: `      
      ["a", "b"].concat(["c", "d"])
      // ["a", "b", "c", "d"]
    `,
  },
  {
    method: "Array#copyWithin",
    argument: "index",
    returnedValue: "array",
    mutable: true,
    nodeVersion: "4.0.0",
    year: 2015,
    tier: "D",
    example: `
      const arr = ['a', 'b', 'c', 'd', 'e'];
      arr.copyWithin(0, 3, 4)
      arr === ["d", "b", "c", "d", "e"]
     `,
  },
  {
    method: "Array#entries",
    argument: "—",
    returnedValue: "iterator",
    mutable: false,
    nodeVersion: "0.12.0",
    year: 2015,
    tier: "D",
  },
  {
    method: "Array.from",
    argument: "iterable",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "4.0.0",
    year: 2015,
    tier: "B",
    example: `
      Array.from("qwe") === ["q", "w", "e"]
    `,
  },
  {
    method: "Array.fromAsync",
    argument: "iterable",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "—",
    year: 2024,
    tier: "D",
  },
  {
    method: "Array.isArray",
    argument: "value",
    returnedValue: "boolean",
    mutable: false,
    nodeVersion: "0.10.0",
    year: 2015,
    tier: "B",
    example: `
      Array.isArray("qwe") === false
      Array.isArray(["q", "w", "e"]) === false
    `,
  },
  {
    method: "Array.of",
    argument: "values",
    returnedValue: "array",
    mutable: false,
    nodeVersion: "4.0.0",
    year: 2015,
    tier: "D",
    example: `      
      Array.of(1) === [1]
      Array.of(1, 2, 3) === [1, 2, 3]
    `,
  },
] as const;

export type ArrayMethod = typeof ArrayMethods[number];

