export const objectsTopics = [
  {
    section: "Структуры",
    problems: [
      "increment-age",
      "find-developers",
      "greet-developers",
      "extract-key",
      "remove-key",
      "keys-projection",
      "hex-to-rgb",
      "who-is-online",
      "csv-parser",
      "browsers-list",
      "inner-join",
    ],
  },
  {
    section: "Словари",
    problems: [
      "replace-digits",
      "total-income",
      "the-office",
      "is-anagram",
      "equal-arrays",
      "valid-brackets",
      "unique-number",
      "non-unique-numbers",
      "movies-rating",
      "shopping-cart",
      // "polish-notation",
      // "remove-duplicate-words",
      "compare-objects",
      "effective-users",
      "merge-objects",
      "where",
      "invert-object",
      "group-anagrams",
      "most-frequent-sum",
    ],
  },
  {
    section: "Сортировки",
    problems: [
      "hash-to-array",
      "my-languages",
      "case-insensitive",
      "sort-by-vowels",
      "sort-students",
      "sort-the-odd",
      "sort-by-freq",
      "teams-ranking",
      "best-results",
      "top-methods",
    ],
  },
] as const;
