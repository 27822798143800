import {useEffect, useState} from "react";
import {toMarkdownV2} from "@telegraf/entity";
import {fetchTelegramUpdates, TelegramUpdates} from "../../api/telegram";
import s from "./TelegramNews.module.css";
import {MarkdownRenderer} from "../ProblemPage/ProblemDescription/MarkdownRenderer";

export function TelegramNews() {
  const [list, setList] = useState<TelegramUpdates | null>(null);

  useEffect(() => {
    fetchTelegramUpdates(0).then(setList);
  }, []);

  if (list === null) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <h1 className={s.mainTitle}>Обновления из телеграм-канала</h1>
      {list.updates.map(update => {
        if (!update.post.channel_post) {
          return null;
        }
        const {text, entities} = update.post.channel_post;

        const markdown = toMarkdownV2({text, entities})
          .replaceAll("\\", "")
          .replaceAll("✓", "\n\n✓")
          .replaceAll(/https:\/\/[^\s]+/g, m => `[${m}](${m})`)
          .replaceAll(/```([^`]+)```/g, (_, content: string) => {
            return "```" + content.replace(/([a-z]+)\n+/i, (_, m) => m + "\n") + "```";
          });

        return (
          <div className={s.post}>
            <MarkdownRenderer children={markdown}/>
          </div>
        );
      })}
    </div>
  );
}
