import {MessageEntity} from "@telegraf/entity/types/types";

interface TelegramPost {
  id: number;
  createdAt: string
  post: {
    update_id: number
    channel_post?: {
      chat: {
        id: number
        type: "channel"
        title: string
      },
      date: number
      text: string
      entities: MessageEntity[],
      message_id: number,
      sender_chat: {
        id: number
        type: "channel"
        title: string
      }
      link_preview_options: {
        url: string;
      }
    },
  }
}

export interface TelegramUpdates {
  totalPages: number;
  updates: TelegramPost[];
}

export function fetchTelegramUpdates(pageNumber: number): Promise<TelegramUpdates> {
  const url = "/api/v1/telegram-channel-update/list?pageNumber=";

  return fetch(url + pageNumber, {
    credentials: 'include',
  }).then(r => r.json())
}
