import cn from "classnames";
import s from "./JsRoadmapPromo.module.css"
import React, {useEffect, useState} from "react";
import {ProblemLink} from "../CoursePage/CourseProblemsList/CourseProblemsList";
import {fpTopics} from "../../domain/problems/maxcode/topics/fpTopics";
import {oopTopics} from "../../domain/problems/maxcode/topics/oopTopics";
import {asyncTopics} from "../../domain/problems/maxcode/topics/asyncTopics";
import {recursionTopics} from "../../domain/problems/maxcode/topics/recursionTopics";
import {bitsTopics} from "../../domain/problems/maxcode/topics/bitsTopics";
import {algoTopics} from "../../domain/problems/maxcode/topics/algoTopics";
import {basicsTopics} from "../../domain/problems/maxcode/topics/basicsTopics";
import {arraysTopics} from "../../domain/problems/maxcode/topics/arraysTopics";
import {objectsTopics} from "../../domain/problems/maxcode/topics/objectsTopics";
import {arrayMethodsTopics} from "../../domain/problems/maxcode/topics/arrayMethodsTopics";
import {useDispatch, useSelector} from "../../app/hooks";
import {promoRoadmapJsSlice} from "../../redux/promoRoadmapJs";

export const JsRoadmapPromoMainCourses = [
  {
    id: "basics",
    title: "Условия и циклы",
    topics: basicsTopics,
  },
  {
    id: "arrays",
    title: "Массивы и строки",
    topics: arraysTopics,
  },
  {
    id: "objects",
    title: "Объекты и сортировки",
    topics: objectsTopics,
  },
  {
    id: "fp",
    title: "Функциональный JavaScript",
    topics: fpTopics,
  },
  {
    id: "oop",
    title: "ООП в JavaScript",
    topics: oopTopics,
  },
  {
    id: "recursion",
    title: "Разбираемся с рекурсией",
    topics: recursionTopics,
  },
  {
    id: "async",
    title: "Асинхронный JavaScript",
    topics: asyncTopics,
  },
  {
    id: "algo",
    title: "Базовые алгоритмы",
    topics: algoTopics,
  },
] as const;

export function JsRoadmapPromo() {
  const index = useSelector(s => s.promoRoadmapJs.index);
  const dispatch = useDispatch();

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.metaKey) {
        return;
      }
      if (e.code === "ArrowLeft") {
        dispatch(promoRoadmapJsSlice.actions.prev());
      }
      if (e.code === "ArrowRight") {
        dispatch(promoRoadmapJsSlice.actions.next());
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    }
  }, []);

  const {id, title, topics} = JsRoadmapPromoMainCourses[index];
  return (
    <div className={cn(id, s.wrapper)}>
      <h1 className={cn(s.header)}>{title}</h1>
      <div className={s.problems}>
        {topics.map(({section, problems}) => {
          return (
            <div className={s.section} key={section}>
              <h3 className={s.sectionHeader}>{section}</h3>

              <div className={s.sectionLinks}>
                {problems.map(slug => <ProblemLink slug={slug} white/>)}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
